
import {
  Component, Prop, Vue, Ref,
} from 'vue-property-decorator';
import { ColumnProps } from './types';
import { FormsRowAction } from '../../types';
import ContextMenuActions from './context-menu-actions.vue';

@Component({
  components: {
    ContextMenuActions,
  },
})
export default class NameColumn extends Vue {
  @Prop({ required: true })
  private readonly props: ColumnProps;

  @Prop({ required: false, default: () => [] })
  private readonly actions: FormsRowAction[];

  @Prop({ required: false, default: () => [] })
  private readonly contextMenuActions: FormsRowAction[];

  @Ref()
  private readonly containerNameRef: HTMLSpanElement;

  @Ref()
  private readonly textNameRef: HTMLSpanElement;

  private isMounted = false;

  private get title() {
    if (this.isMounted && this.textNameRef.offsetWidth > this.containerNameRef.offsetWidth) {
      return this.props.row.name.title;
    }
    return '';
  }

  public mounted() {
    this.isMounted = true;
  }
}
