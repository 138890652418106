import { render, staticRenderFns } from "./data-table.vue?vue&type=template&id=a97eefae&scoped=true&"
import script from "./data-table.vue?vue&type=script&lang=ts&"
export * from "./data-table.vue?vue&type=script&lang=ts&"
import style0 from "./data-table.vue?vue&type=style&index=0&id=a97eefae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a97eefae",
  null
  
)

export default component.exports