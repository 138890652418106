
import {
  Component, Prop, Vue, Ref, Provide,
} from 'vue-property-decorator';
import { Row, Column } from './types';

@Component
export default class DataTable extends Vue {
  @Provide('setDropdownClicked')
  public provideSetDropdownClicked = this.setDropdownClicked;

  @Provide('onDropdownChange')
  public provideOnDropdownChange = this.onDropdownChange;

  @Prop({ required: true })
  private readonly tableData: Row[];

  @Prop()
  private readonly columns: Column[];

  @Prop({ required: false, default: [] })
  private readonly selectedIds: string[];

  @Ref()
  private readonly dataTableRef: {
    checkRow: (row: Row, rowIndex: number, event: string) => void;
  };

  private dropdownRow: Row | null = null;

  private isDropdownClicked = false;

  private rowClass(row: Row) {
    return `data-table-row ${row.id === this.dropdownRow?.id ? 'opened' : ''}`;
  }

  private onDropdownChange(e: boolean, row: Row) {
    if (!e && row.id !== this.dropdownRow?.id) {
      return;
    }

    this.dropdownRow = e ? row : null;
  }

  private customChecked(checkedRow: Row, visibleRow: Row) {
    // In case when many rows are selected and row deletion is triggered from the context menu
    // https://github.com/buefy/buefy/issues/552

    return checkedRow.id === visibleRow.id && this.selectedIds.indexOf(checkedRow.id) !== -1;
  }

  private async onCellClick(row: Row, rowIndex: number) {
    // For the preventing bubble effect when the dropdown-trigger is clicked
    if (!this.isDropdownClicked) {
      this.dataTableRef.checkRow(row, rowIndex, 'click');
    }
    this.isDropdownClicked = false;
  }

  private setDropdownClicked() {
    this.isDropdownClicked = true;
  }

  private selectionChanged(checkedList: Row[]) {
    this.$emit(
      'selectionChanged',
      checkedList.map((r) => r.id),
    );
  }
}
