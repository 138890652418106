
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Loader extends Vue {
  @Prop({ required: false, default: 2.5 })
  private readonly size: number;

  @Prop({ required: false, default: 0.2 })
  private readonly borderWidth: number;

  @Prop({ required: false, default: 'rgb(219, 219, 219)' })
  private readonly color: string;

  get style() {
    return {
      height: `${this.size}em`,
      width: `${this.size}em`,
      'border-width': `${this.borderWidth}em`,
      'border-left-color': this.color,
      'border-bottom-color': this.color,
    };
  }
}
