
import {
  Component, Prop, Vue, Ref, Watch, Inject,
} from 'vue-property-decorator';
import { createVueComponent } from '@/utils/index.js';
import ContextMenuDropdown from './context-menu-dropdown.vue';
import { ColumnProps, Row, RowAction } from './types';

@Component
export default class ContextMenuActions extends Vue {
  @Inject()
  readonly setDropdownClicked: () => void;

  @Inject()
  readonly onDropdownChange: (e: boolean, row: Row) => void;

  @Prop({ required: true })
  private readonly props: ColumnProps;

  @Prop({ required: false, default: () => [] })
  private readonly actions: RowAction[];

  @Prop({ required: false, default: () => [] })
  private readonly contextMenuActions: RowAction[];

  @Ref()
  private readonly dropdownWrapperRef: HTMLDivElement;

  private Dropdown: (Vue & { toggle: () => void; isActive: () => boolean }) | null = null;

  private dropdownIsLoaded = false;

  get dropdownIsActive() {
    return this.Dropdown?.isActive;
  }

  private onContextMenuClick() {
    if (!this.dropdownIsLoaded) {
      this.createDropdown();
    }
    this.Dropdown?.toggle();
    document.body.click();
    this.dropdownIsLoaded = true;
  }

  private createDropdown() {
    this.Dropdown = createVueComponent(ContextMenuDropdown, {
      data: () => ({
        row: this.props.row,
        contextMenuActions: this.contextMenuActions,
      }),
      methods: {
        onDropdownChange: this.onDropdownChange,
      },
    });
    this.Dropdown?.$mount(this.dropdownWrapperRef);
  }

  @Watch('props.row')
  private onRowChanged(row: Row) {
    if (this.dropdownIsLoaded && this.Dropdown) {
      this.Dropdown.$set(this.Dropdown, 'row', row);
    }
  }

  @Watch('contextMenuActions')
  private onActionsChanged(actions: RowAction[]) {
    if (this.dropdownIsLoaded && this.Dropdown) {
      this.Dropdown.$set(this.Dropdown, 'contextMenuActions', actions);
    }
  }
}
