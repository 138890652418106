
import { Component, Prop, Vue } from 'vue-property-decorator';
import ToolbarButton from '../toolbar-button.vue';
import { Action } from './types';

@Component({
  components: {
    ToolbarButton,
  },
})
export default class ToolbarActions extends Vue {
  @Prop() private readonly actions: Action[];
}
