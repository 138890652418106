
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ToolbarButton extends Vue {
  @Prop({ required: false, default: '' })
  private readonly title: string;

  @Prop({ required: false, default: '' })
  private readonly icon: File | string[];

  @Prop({ required: false, default: 'secondary' })
  private readonly type: 'primary' | 'secondary';

  @Prop({ required: false, default: false })
  private readonly disabled: boolean;

  get isIconComponent() {
    return Array.isArray(this.icon);
  }
}
