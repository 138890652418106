import { render, staticRenderFns } from "./context-menu-actions.vue?vue&type=template&id=155a0004&scoped=true&"
import script from "./context-menu-actions.vue?vue&type=script&lang=ts&"
export * from "./context-menu-actions.vue?vue&type=script&lang=ts&"
import style0 from "./context-menu-actions.vue?vue&type=style&index=0&id=155a0004&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "155a0004",
  null
  
)

export default component.exports