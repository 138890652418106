
import { Component, Vue, Ref } from 'vue-property-decorator';
import { FormRow, FormsRowAction } from '../../types';

@Component
export default class ContextMenuDropdown extends Vue {
  @Ref()
  private readonly dropdownRef: { toggle: () => void; isActive: boolean };

  onDropdownChange: (e: boolean, row: any) => void;

  public row: FormRow;

  public contextMenuActions: FormsRowAction[];

  public isActive = false;

  public toggle() {
    this.dropdownRef.toggle();
  }

  private async onActiveChange(e: boolean) {
    this.isActive = e;
    this.onDropdownChange(e, this.row);
  }
}
